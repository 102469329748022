export const version = process.env.REACT_APP_VERSION
export const navbarBreakPoint = 'xl' // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg'
export const themeVariants = ['light', 'dark', 'auto']
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: true,
  theme: 'auto',
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'inverted'
}

export const permissionOptions = [
  {
    targetRouteId: 'settings_user_mgmt',
    label: 'User Management',
    value: 0X00000001,
    restricted: true // This is for the SuperAdmin only and will not show up as an assignable permission
  },
  {
    targetRouteId: 'contacts',
    label: 'Edit Contacts',
    value: 0X00000002
  },
  {
    targetRouteId: 'developer',
    label: 'Manage Developer',
    value: 0X00000004
  },
  {
    targetRouteId: 'billing',
    label: 'Manage Billing',
    value: 0X00000008
  },
]

export const contactTypes = [
  {
    label: 'Acquisitions & Development',
    value: 77
  },
  {
    label: 'CFO',
    value: 78
  },
  {
    label: 'CFO',
    value: 79
  },
  {
    label: 'CHO',
    value: 80
  },
  {
    label: 'Co-Owner',
    value: 7
  },
  {
    label: 'Controller',
    value: 81
  },
  {
    label: 'COO',
    value: 82
  },
  {
    label: 'Director',
    value: 83
  },
  {
    label: 'Dist. Manager',
    value: 84
  },
  {
    label: 'General Manager',
    value: 85
  },
  {
    label: 'Manager',
    value: 86
  },
  {
    label: 'Office Manager',
    value: 87
  },
  {
    label: 'Operations',
    value: 88
  },
  {
    label: 'Owner',
    value: 3
  },
  {
    label: 'President',
    value: 89
  },
  {
    label: 'Project Manager',
    value: 90
  },
  {
    label: 'Retail Operations',
    value: 91
  },
  {
    label: 'Vice President',
    value: 92
  },
]

export const pricingData = [
  {
    id: 0,
    title: 'Core',
    price: [0, 0],
    period: 'm',
    icon: 'house',
    url: '#!',
    buttonText: 'Start free trial',
    isFeatured: true,
    features: [
      { id: 0, title: 'Basic Analytics', isActive: true },
      { id: 1, title: 'Single User Access', isActive: true },
      { id: 2, title: '1 Months Data Visibility', isActive: true },
      { id: 3, title: 'Api & Developer Tools', isActive: true },
      { id: 4, title: 'Disputes', isActive: true },
      { id: 5, title: 'Contact Management', isActive: true },
      // { id: 6, title: 'Unlimted Tags', isActive: false },
      // { id: 7, title: 'Custom Fields', isActive: false }
    ]
  },
  {
    id: 1,
    title: 'Advanced',
    price: [35, 25],
    period: 'm',
    icon: 'building',
    url: '#!',
    buttonText: 'Purchase Now',
    isFeatured: true,
    features: [
      { id: 0, title: 'Everything from Core, plus...', isActive: true, icon: 'arrow-left' },
      { id: 1, title: 'Advanced Analytics', isActive: true },
      { id: 2, title: '5 users', isActive: true },
      { id: 3, title: '6 Month Data Visibility', isActive: true },
      // { id: 3, title: 'Api & Developer Tools', isActive: true },
      // { id: 4, title: 'Disputes', isActive: true },
      // { id: 5, title: 'Contact Management', isActive: true },
      // { id: 6, title: 'Unlimted Tags', isActive: true },
      // { id: 7, title: 'Custom Fields', isActive: true }
    ]
  },
  {
    id: 2,
    title: 'Enterprise',
    price: [45, 35],
    period: 'm',
    icon: 'city',
    url: '#!',
    buttonText: 'Purchase Now',
    isFeatured: true,
    features: [
      { id: 0, title: 'Everything from Advanced, plus...', isActive: true, icon: 'arrow-left' },
      // { id: 0, title: 'Advanced Analytics', isActive: true },
      { id: 1, title: 'Unlimited Users', isActive: true },
      { id: 2, title: 'Unlimited Data Visibility', isActive: true },
      // { id: 3, title: 'Api & Developer Tools', isActive: true },
      // { id: 4, title: 'Disputes', isActive: true },
      // { id: 5, title: 'Contact Management', isActive: true },
      // { id: 6, title: 'Unlimted Tags', isActive: true },
      // { id: 7, title: 'Custom Fields', isActive: true }
    ]
  }
]

export const webhookEvents = [
  {
    value: 'pre-redemption',
    label: 'Pre Redemption Conversion',
    help: 'Triggers once the cashier enters the pump number the driver is filling at.'
  },
  {
    value: 'post-redemption',
    label: 'Post Redemption',
    help: 'Triggers once the cashier enters the number of gallons for a redemption.'
  },
  {
    value: 'pre-reservation',
    label: 'Pre Reservation',
    help: 'Triggers upon reservation creation.  This is typically used to validate pre-authorized payments.'
  },
  {
    value: 'start-redemption',
    label: 'Start Redemption',
    help: 'Triggers once the driver checks in and is ready to redeem.'
  },
  {
    value: 'pricing-update',
    label: 'Pricing Update',
    help: 'Triggers as location pricing changes.'
  }
]

export const webhookEnvironments = [
  {
    label: 'PROD',
    value: 'prod',
    help: 'Live events from Production.  This data is billable.'
  },
  {
    label: 'TEST',
    value: 'test',
    help: 'An isolated sandboxed system where requests can be tested during development.'
  },
  {
    label: 'DEV',
    value: 'dev',
    help: 'Internal Development'
  }
]

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings }
